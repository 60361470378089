import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  CardText
} from "reactstrap";


export const CrownBridgeCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/dental_crown_bridge_models.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Crown & Bridge Models</CardTitle>
              <CardText>
                High accuracy removable die models with crisp margins, precise contacts,vand consistent occlusion that look   and feel like gypsum. 
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Order Now
              </Button>
            </CardBody>
          </Card>
);

export const ClearAlignerCard = () => (
          <Card style={{ width: "18rem" }}
          onClick={e => e.preventDefault()}
          >
            <CardImg
              alt="..."
              src={require('assets/img/product/dental_clear_aligner_models.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Clear Aligner Models</CardTitle>
              <CardText>
                Fast, consistent, and easy production of short horseshoe arches. Our labs print for vacuum forming with unmatched value and workflow.
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Order Now
              </Button>
            </CardBody>
          </Card>
);

export const SurgicalGuidesCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/dental_surgical_guides.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Surgical Guides</CardTitle>
              <CardText>
              Quick, high-precision implant placement made affordable. Use with standard autoclaves and drill sleeves to fit seamlessly into your regular workflow.
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Order Now
              </Button>
            </CardBody>
          </Card>
);

export const SplintsGuardsCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/dental_splints_occlusal_guards.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Splints and Occlusal Guards</CardTitle>
              <CardText>
              3D print beautiful, optically transparent long-term splints with ISO 20795 compliant mechanical properties.
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Order Now
              </Button>
            </CardBody>
          </Card>
);

export const PatternCastingCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/dental_casting_models.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Patterns for Casting / Pressing</CardTitle>
              <CardText>
              3D print highly accurate patterns for crowns and bridges, copings and substructures, and RPD frames.
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Order Now
              </Button>
            </CardBody>
          </Card>
);

export const HawleyRetainersCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/dental_hawley_retainers.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Hawley Retainers</CardTitle>
              <CardText>
              Produce orthodontic models for wire Hawley retainers to improve efficiency and reduce turnaround time.
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Order Now
              </Button>
            </CardBody>
          </Card>
);

export const DenturesCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/dental_full_dentures.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Full Dentures</CardTitle>
              <CardText>
              Produce 3D printed dentures accurately and reliably with an efficient, cost-effective manufacturing solution.
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Dental
              </Button>
            </CardBody>
          </Card>
);

