import React from "react";
import firebaseAuth from 'firebaseConfig';
import { connect } from "react-redux";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import MessengerCustomerChat from 'react-messenger-customer-chat';

const contactFormRef = firebaseAuth.database().ref().child("users/"+localStorage.getItem("appToken")+"/contact_form");
// localStorage.removeItem("firebaseUser")
// const firebaseUser = JSON.parse(localStorage.getItem("firebaseUser"));
    
class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props,
      form: [],
      alert: false,
      alertData: {}
    };
  }

  showAlert(type, message) {
    this.setState({
      alert: true,
      alertData: { type, message }
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 4000)
  }

  resetForm() {
    this.refs.contactForm.reset();
  }

  componentWillMount() {
    if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
        }
    } else { console.log("Authstate confirmed") }
    
    let formRef = contactFormRef.orderByKey().limitToLast(6);
    formRef.on('child_added', snapshot => {
      const { name, email, phone, message } = snapshot.val();
      const data = { name, email, phone, message };
      this.setState({ form: [data].concat(this.state.form) });
    })
  }    
  sendMessage(e) {
    e.preventDefault();
    const params = {
      name: this.inputName.value,
      email: this.inputEmail.value,
      city: this.inputCity.value,
      phone: this.inputPhone.value,
      message: this.textAreaMessage.value
    };
    if (params.name && params.email && params.phone && params.phone && params.message) {
        contactFormRef.push(params).then(() => {
        this.showAlert('success', 'Your message was sent successfull');
      }).catch(() => {
        this.showAlert('danger', 'Your message could not be sent');
      });
      this.resetForm();
    } else {
      this.showAlert('warning', 'Please fill the form');
    };
  }


  render() {    return (
    <>
      <MessengerCustomerChat
          pageId="6194773961"
          appId="2608038802650771"
          htmlRef="https://precision3d.net"
      />

      <Header />
      <div>
        {this.state.alert && <div className={`alert alert-${this.state.alertData.type}`} role='alert'>
          <div className='container'>
            {this.state.alertData.message}
          </div>
        </div>}
        <Container className=" mt--7" fluid>

        <div className='container' style={{ padding: `40px 0px` }}>
          <div className='row'>
                <div className='col-sm-8'>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h2>Contact Form</h2>
              </CardHeader>
              <CardBody>
                  <form onSubmit={this.sendMessage.bind(this)} ref='contactForm' >
                    <div className='form-group'>
                      <label htmlFor='name'>Name</label>
                      <input type='text' className='form-control' id='name' placeholder={this.state.authState.user.displayName} ref={name => this.inputName = name} />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1'>Email</label>
                      <input type='email' className='form-control' id='email' placeholder={this.state.authState.user.email} ref={email => this.inputEmail = email} />
                    </div>
                    {/*<div className='form-group'>
                      <label htmlFor='city'>City</label>
                      <select className='form-control' id='city' ref={city => this.inputCity = city}>
                        <option value='Dallas'>Dallas</option>
                        <option value='Medellin'>Medellin</option>
                      </select>
                    </div>*/}
                    <div className='form-group'>
                      <label htmlFor='phone'>Phone</label>
                      <input className='form-control' id='phone' placeholder={this.state.authState.user.phone} ref={phone => this.inputPhone = phone} />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='message'>Message</label>
                      <textarea className='form-control' id='message' rows='3' ref={message => this.textAreaMessage = message}></textarea>
                    </div>
                    <button type='submit' className='btn btn-primary'>Send</button>
                  </form>
            </CardBody>
            </Card>
                </div>
          </div>
        </div>
      </Container>
      </div>
      </>
    );
  }
}
            // <div className='col-sm-8'>
            //   <div className='row'>
            //     {this.state.form.map(form =>
            //       <div className='col-sm-6' key={form.phone} style={{ margin: `0px 0px 30px 0px` }}>
            //         <div className='card'>
            //           <div className='card-body'>
            //             <h4 className='card-title'>{form.name}</h4>
            //             <h6 className='card-subtitle mb-2 text-muted'>{form.city}</h6>
            //             <p className='card-text'>{form.message}</p>
            //             <a href={`tel:${form.phone}`} className='card-link'>{form.phone}</a>
            //             <a href={`mailto:${form.email}`} className='card-link'>{form.email}</a>
            //           </div>
            //         </div>
            //       </div>)}
            //   </div>
            // </div>

const mapStateToProps = state => ({
  ...state
});
export default connect(
  mapStateToProps,
)(Support);
