import * as firebase from "firebase";
var config = {

  apiKey: "AIzaSyAb9jc2kabuCvwGW58cJxRQe776kYld0IE",
  authDomain: "precision3dnetworks.firebaseapp.com",
  databaseURL: "https://precision3dnetworks.firebaseio.com",
  projectId: "precision3dnetworks",
  storageBucket: "precision3dnetworks.appspot.com",
  messagingSenderId: "601031768073",
  appId: "1:601031768073:web:8c26df98948b08885f60d8",
  measurementId: "G-BPW9NDF4NT"

};
let firebaseConfig = firebase.initializeApp(config);
export default firebaseConfig;

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;