import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

import routes from "routes.js";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    // if (this.props.authState.loggedIn) {
    // // if (localStorage.getItem("appToken") || (this.props.authState.loggedIn) ) {
    // if (localStorage.getItem("appToken")!==undefined) {
    // if ((localStorage.getItem("appToken")!==undefined) && (this.props.authState.loggedIn) ) {
    //   return <Redirect to="/client/profile" />;
    // }
    return (
      <>
        <div className="main-content">
          <AuthNavbar />
          <div className="header bg-gradient-info py-7 py-lg-6">

          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="10" md="6">
                  <h2 className="text-white">Welcome to Precision 3D Networks!</h2>
                  <h3 className="text-lead text">
                    Your price friendly provider for 3D printing, with free next-day shipping.
                  </h3>
                </Col>
              </Row>
            </div>
          </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
});
  // authState: state.authState
export default connect(
  mapStateToProps,
  {}
)(Auth);
