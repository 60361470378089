import React from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button } from "reactstrap";
import firebase from 'firebaseConfig';

// import getCustomerOrders from 'actions/getCustomerOrders'

// import {Checkout} from 'components/Checkout/CheckoutForm';
// import UploadComponent from 'components/Upload/UploadComponent';
// import FileGallery from './FileGallery';
// import Shipping from 'components/Forms/Shipping'

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import MessengerCustomerChat from 'react-messenger-customer-chat';
 
// const downloadRef = firebase.storage()
// const eventsRef = firebase.database().ref("events").orderByChild('data/object/metadata/userid')
   const appToken = localStorage.getItem("appToken")
   const orderRef = firebase.database().ref().child("users/"+appToken+"/orders");
class Orders extends React.Component {
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
    orderRef.off()

  }          
  constructor(props){
      super(props);

      this.onChange = this.onChange.bind(this)
      this.to_datetime = this.to_datetime.bind(this)
      this.state = {...props, orders:[]}
  }
  componentDidMount() {
    // this.props.getCustomerOrders();
    // this.props.getAllUploadsAction();
  }
  appToken = appToken;
    
  componentWillMount() {

    // if (this.state.authState === undefined) { 
    //     console.log("Authstate unconfirmed")
    //   if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
    //     }        
    // } else { 
    //   if (this.state.authState.loggedIn === undefined) { 
    //     if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
    //     }
    //   } else { console.log("Authstate confirmed") }
    // }
    this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          

    // this.state.authState.user.uid
    let orders = []
    var that = this;
    console.log("Orders ", this.appToken)
 
    // this.state.orders = orders
    orderRef.on('value', function(snapshot) {
      // if (that._isMounted) {
        snapshot.forEach(function(data) { 
          orders.push(data.val()); 
          console.log("order data...",data.toJSON()); 
          that.setState({orders:orders})    
          // onchange(orders,orders)
          });
      // }
    });
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
    console.log('[print]', this.state)
  };

  to_datetime = (epoch) => {
    var d = new Date(epoch*1000);
    // var month = 
    var dformat = (d.getMonth()+1).toString().padStart(2,'0') + "/" + 
          (d.getDate()).toString().padStart(2,'0') + "/" + 
          (d.getFullYear()).toString().padStart(2,'0') + " " + 
          (d.getHours()).toString().padStart(2,'0') + ":" + 
          (d.getMinutes()).toString().padStart(2,'0') + ":" + 
          (d.getSeconds()).toString().padStart(2,'0');
    return dformat.toString();
  };
  render() {
    // console.log(this.appToken, this.state)
    // const hostname = window && window.location && window.location.hostname;
    // console.log("Hostname", hostname)
    var my_orders
    if (this.state.orders[0]) { 
        my_orders = this.state.orders.map((prop, key) => {
                    return (

                    <tr key={key}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt={key}
                              src=""
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              {prop.files}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{this.to_datetime(prop.created)}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                            {prop.status}
                        </Badge>
                      </td>
                      <td>
                        <div className="test-left">
                            {prop.material}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">0%</span>
                          <div>
                            <Progress
                              max="100"
                              value="0"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#"
                              onClick={e => e.preventDefault()}
                            >
                              Details
                            </DropdownItem>
                            <DropdownItem
                              href="#"
                              onClick={e => e.preventDefault()}
                            >
                              Pause Production
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    )})
        
    } else { my_orders = <tr><td> Loading... </td></tr> }

  	return (
      <>
 
        <MessengerCustomerChat
          pageId="6194773961"
          appId="2608038802650771"
          htmlRef="https://precision3d.net"
        />
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
        {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Order Status and Details</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Part Order</th>
                      <th scope="col">Time Created</th>
                      <th scope="col">Status</th>
                      <th scope="col">Material</th>
                      <th scope="col">Completion</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                 { my_orders }







                  </tbody>
                </Table>

                 { this.state.orders &&
                  this.state.orders.length > 5 &&
                  this.state.orders[0] &&  (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          className="pagination justify-content-end mb-0"
                          listClassName="justify-content-end mb-0"
                        >
                          <PaginationItem className="disabled">
                            <PaginationLink
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem className="active">
                            <PaginationLink
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              1
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              2 <span className="sr-only">(current)</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              3
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fas fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </nav>
                    </CardFooter>)
                }
              </Card>
            </div>
          </Row>
        </Container>
      </>
  		);    
   }
}

        // {this.props.authState}

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
//   logoutAction: () =>
//     dispatch(logoutAction())

  // getAllUploadsAction: () => dispatch(getAllUploadsAction())

  // ,addStatCardAction: (
  //   statName,
  //   statDescription,
  //   statIcon,
  //   statIconColor,
  //   statFooterIcon,
  //   statFooterIconState,
  //   statFooterPercentage,
  //   statFooterText
  // ) =>
  //   dispatch(
  //     addStatCardAction(
  //       statName,
  //       statDescription,
  //       statIcon,
  //       statIconColor,
  //       statFooterIcon,
  //       statFooterIconState,
  //       statFooterPercentage,
  //       statFooterText
  //     )
  //   )
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
