import React from "react";
import { connect } from "react-redux";
// import {firebaseAuth} from 'firebaseConfig';
import firebaseAuth from 'firebaseConfig';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";


const customerFormRef = firebaseAuth.database().ref().child("users/"+localStorage.getItem("appToken")+"/customer_information");

class Customer extends React.Component {
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
    customerFormRef.off()
  } 

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
    this.props.handler(stateName, value);
  };

  constructor(props) {
      super(props);
      this.state = { 
        company: "",
        name: "",
        email: "",
        phone: "",
        form: [],
      }
    }

 

  componentWillMount() {
    // if ('authState' in this.state && this.state.authState.loggedIn === undefined) { 
    //     console.log("Authstate unconfirmed")
    //     if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
    //     }
    // } else { 
      this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });
    //   console.log("Authstate confirmed") 
    // }
    let that = this
    customerFormRef.on('value', snapshot => {
      // if (that._isMounted) {
        if (snapshot.exists()) {
          const { company, name, email, phone } = snapshot.val();
          const data = { company, name, email, phone };
          this.setState({form:[data]})
        } else {
          const data = { company:"", name:"", email:"", phone:"" };
          this.setState({form:[data]})
          this.showAlert('danger', 'First visit to database reference'); 
        }
      // }
    })
  }

  updateFirebase(e) {
    e.preventDefault();
    const params = {      
      company: this.state.company,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
    };
    if (params.company===undefined) params.company = ""
    if (params.name===undefined) params.name = ""
    if (params.email===undefined) params.email = ""
    if (params.phone===undefined) params.phone = ""

    customerFormRef.update(params).then(() => {
      this.showAlert('success', 'Your data was updated successfull');
    }).catch(() => { this.showAlert('danger', 'Your data could not be updated'); });
    console.log(this.state)
    
  }

  showAlert(type, message) {
    this.setState({
      alert: true,
      alertData: { type, message }
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 4000)
    // if (message.type=='success'){this}
  }

  render() {
    var customer_information
    // if (this._isMounted) {
    if (this.state.form[0]) { 
      customer_information = this.state.form.map(form =>
          <Form role="form" key="customer_information">
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name"> Name </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"                    
                    onBlur={this.updateFirebase.bind(this)}
                    placeholder={this.state.authState.user.displayName} 
                    type="name"
                    onChange={e => this.onChange("name", e.target.value)}
                    defaultValue={form.name}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-company"> Company </label>
                  <Input
                    className="form-control-alternative"
                    id="input-company"                    
                    onBlur={this.updateFirebase.bind(this)}
                    placeholder="Company Name"
                    type="company"
                    onChange={e => this.onChange("company", e.target.value)}
                    defaultValue={form.company}                      
                  />
                </FormGroup>
                </Col>
              </Row>
              <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email"> Email </label>
                    <Input id="email"
                      className="form-control-alternative"
                      id="input-email"                    
                      onBlur={this.updateFirebase.bind(this)}
                      placeholder={this.state.authState.user.email} 
                      type="email"
                      onChange={e => this.onChange("email", e.target.value)}
                      defaultValue={form.email}                                            
                    />
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-phone"> Phone </label>
                    <Input
                      className="form-control-alternative"
                      id="input-phone"                    
                      onBlur={this.updateFirebase.bind(this)}                    
                      placeholder="Phone"
                      type="phone"
                      onChange={e => this.onChange("phone", e.target.value)}
                      defaultValue={form.phone}                                            
                    />
                </FormGroup>
                </Col>
              </Row>
            </Form>
            )
      } else { customer_information = <div> Loading... </div> }

    return (
      <>
        {this.state.alert && <div className={`alert alert-${this.state.alertData.type}`} role='alert'>
          <div className='container'>
            {this.state.alertData.message}
          </div>
        </div>}      
        {customer_information}
      </>
    );
  }
}


const mapStateToProps = state => ({
  ...state
});

// export default connect(
//   mapStateToProps,
// )(Customer);
export default Customer;