import React from "react";
import { connect } from "react-redux";
import { Alert, Button, Label } from "reactstrap";
import firebase from 'firebaseConfig';
import axios from 'axios';
import FileUploader from "react-firebase-file-uploader";
const userAuth = localStorage.getItem("appToken")
const filesRef = firebase.database().ref().child("users/"+userAuth+"/files");

class UploadComponent extends React.Component {


  constructor(props){
    super(props);
    this.state = {
      // # Image Uploads
      filenames: [],
      downloadURLs: [],
      isUploading: false,
      uploadProgress: 0
    };
  }

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });

  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });

  handleUploadError = error => {
    this.setState({
      isUploading: false
      // Todo: handle error
    });
    console.error(error);
  };


  handleUploadSuccess = async filename => {

    const downloadURL = await firebase
      .storage()
      .ref("users/"+userAuth+"/files")
      .child(filename)
      .getDownloadURL();

    this.setState(oldState => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: [...oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false
    }));

    const params = {      
      filename: filename,
      urlFile: downloadURL,
      urlImage: "",
      uploadDate: Math.floor(new Date() /1000),
      notes: "",
    };
    
    filesRef.push(params).then(() => {
      console.log('success', 'Your data was updated successfull');
    }).catch(() => { console.log('danger', 'Your data could not be updated'); });
    console.log(this.state)



    // Send to Blender API
    const body = 
      {
        url: downloadURL,
        userid: userAuth,
        files: filename,
        material: "default"
      }; 
    const hostname = window && window.location && window.location.hostname;
    const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
    const URL = "http://104.237.146.109:9310/fileUpload"
    axios({
      method: 'post',
      url: PROXY_URL+URL,
      headers: {'Content-Type':'text/plain'},
      data: body
      })
    .then(response => { console.log(response); })
    .catch(err => console.log(err));

    // window.parent.location = window.parent.location.href;

  }

  // handleUploadSuccess = async filename => {

   

  //   const downloadURL = await firebase
  //     .storage()
  //     .ref("users/"+userAuth+"/files")
  //     .child(filename)
  //     .getDownloadURL();

  //   this.setState(oldState => ({
  //     filenames: [...oldState.filenames, filename],
  //     downloadURLs: [...oldState.downloadURLs, downloadURL],
  //     uploadProgress: 100,
  //     isUploading: false
  //   }));

  //   const params = {      
  //     filename: filename,
  //     urlFile: downloadURL,
  //     urlImage: "",
  //     uploadDate: Math.floor(new Date() /1000),
  //     notes: "",
  //   };
    
  //   filesRef.push(params).then(() => {
  //     console.log('success', 'Your data was updated successfull');
  //   }).catch(() => { console.log('danger', 'Your data could not be updated'); });
  //   console.log(this.state)

  //   window.parent.location = window.parent.location.href;
  // }
// .rar    application/x-rar-compressed, application/octet-stream
// .zip    application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip

  render() {
  	return (
      <label color='primary' type='button' fontSize="10px">
        Browse...
        <FileUploader
          accept="application/x-rar-compressed, application/octet-stream, application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
          name="file-uploader"
          filename={file => file.name}
          // randomFilename
          storageRef={firebase.storage().ref("users/"+userAuth+"/files")}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
          onProgress={this.handleProgress}
          hidden
        />
      </label>
  		);    
   }
}

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({ });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadComponent);
