import React from "react";
// import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

import StripeScriptLoader from 'react-stripe-script-loader'
import {
  StripeProvider,
  // Elements,
  // CardNumberElement,
} from 'react-stripe-elements'
 
class Admin extends React.Component {
  componentDidMount(){
    this._isMounted = true;
    // this.page = return (<div>)
  }      
  componentWillMount(){
    this._isMounted = true;    
  }
  componentWillUnmount(){
    this._isMounted = false;
    this.stript_render_component = null
    // let mountNode = React.findDOMNode(this.refs.mainContent)
    // let unmount = React.unmountComponentAtNode(mountNode)
    // ReactDOM.unmountComponentAtNode(document.getElementById('app'))
  }          
  constructor(props) { 
    super(props)
    this._isMounted = false;
    // var this.page 
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/order") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // hostname = window && window.location && window.location.hostname;
  // // console.log("window.location", window.location)
  // stripe_api_key = (this.hostname === 'kronos9.com') ? 
  //   "pk_test_XeKtUlm8wbZA71uEzbj9tvtX00iJKLnwlJ" : "pk_live_kTL0Wtle9GycA1DzCHKjpRrZ003pPKSthd"

  //          // ref = "StripeScriptLoader"
  //  stript_render_component = <StripeScriptLoader
  //           uniqueId='precision3dnetworks'
  //           script='https://js.stripe.com/v3/'
  //           loader="Loading..."
  //           _isMounted={this._isMounted}>
  //         <StripeProvider apiKey={this.stripe_api_key}>
  
  //         <Switch>{this.getRoutes(routes)}</Switch>
  
  //         </StripeProvider>
  //         </StripeScriptLoader>
  render() {

    if (localStorage.getItem("appToken")===undefined) {
      return <Redirect to="/auth/login" />;
    }
    
    var stript_render_component
    var hostname = window && window.location && window.location.hostname;
    var stripe_api_key = (hostname === 'kronos9.com') ? 
      "pk_test_XeKtUlm8wbZA71uEzbj9tvtX00iJKLnwlJ" : "pk_live_kTL0Wtle9GycA1DzCHKjpRrZ003pPKSthd"

    if (this._isMounted) {
      stript_render_component = <StripeScriptLoader
            uniqueId='precision3dnetworks'
            script='https://js.stripe.com/v3/'
            loader="Loading...">
          <StripeProvider apiKey={stripe_api_key}>
  
          <Switch>{this.getRoutes(routes)}</Switch>
  
          </StripeProvider>
          </StripeScriptLoader>
      } else { stript_render_component = <div> Loading... </div> }

    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/client/profile",
            imgSrc: require("assets/img/brand/p3d.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          
          

          {stript_render_component}
          

          
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});
export default connect(
  mapStateToProps,
  {}
)(Admin);
