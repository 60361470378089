// import firebase from "firebaseConfig";
import {firebaseAuth, googleProvider} from 'firebaseConfig'

export const loginAction = (email, password) => async dispatch => {
  // firebase offers us this function signInWithEmailAndPassword
  // which will automatically create the user for us
  // it only has two arguments, the email and the password
  firebaseAuth()
    // .auth()
    .signInWithEmailAndPassword(email, password)
    .then(function(user) {
      dispatch({ type: "login", payload: "true" });
      dispatch({ type: "firebaseUser", payload: user.user });
      localStorage.setItem("appToken", user.user.uid);
      localStorage.setItem("firebaseUser", JSON.stringify(user.user));
    })
    .catch(function(error) {
      alert(error);
    });
};


export const loginActionGoogle = () => async dispatch => {
    firebaseAuth()
    // .auth()
    // .signInWithRedirect(googleProvider)
    .signInWithPopup(googleProvider)
    .then(function(user) {
      dispatch({ type: "login", payload: "true" });
      dispatch({ type: "firebaseUser", payload: user.user });
      localStorage.setItem("appToken", user.user.uid);
      localStorage.setItem("firebaseUser", JSON.stringify(user.user));
    })
    .catch(function(error) {
      alert(error);
    });
};

export const updateGoogleAction = () => async dispatch => {
    // const firebaseUser = JSON.parse(localStorage.getItem("firebaseUser"));  
    dispatch({ type: "login", payload: "true" });
    dispatch({ type: "firebaseUser", payload: localStorage.getItem("firebaseUser") });
    // localStorage.setItem("appToken", localStorage.getItem("appToken"));
    console.log("[updateGoogleAction] Just sent dispatches")
}
// export default loginAction;
