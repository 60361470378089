import React from "react";
import { connect } from "react-redux";
// import { GoogleLogin } from 'react-google-login';
import {loginAction, loginActionGoogle} from "actions/loginAction"; //updateGoogleAction
import registerAction from "actions/registerAction";
import Carousel from 'components/Carousel/LandingCarousel';
import Header from "components/Headers/Header.jsx";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container
} from "reactstrap";

const responseGoogle = (response) => {
    console.log(response);
}

class Register extends React.Component {
 
  state = {
    name: "",
    email: "",
    password: ""
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  };
    // <GoogleLogin
    //   clientId="601031768073-iebg8mvuhc6if0b5deufjvek6jv7qvn2.apps.googleusercontent.com" 
    //   buttonText="Google"
    //   onSuccess={responseGoogle}
    //   onFailure={responseGoogle}
    //   cookiePolicy={'single_host_origin'}
    // />
    // console.log("[Landing] this.state:",this.state)
  render() {

    return (
      <>
      <Header/>
      <Container>
        <div className="header-body text-center mb-7">
          <Row className="justify-content-center">
            <Col lg="10" md="6">
              <h2 className="text-black">Welcome to Precision 3D Networks!</h2>
              <h3 className="text-black text">
                Your price friendly provider for 3D printing, with next-day shipping.
              </h3>
            </Col>
          </Row>
        </div>
      </Container>

      <Col lg="10" md="8">
          <h2 className="text-white">The first model is on us, every 5th model is free, with reduced pricing on jobs with multiple parts.</h2>
          <br/>
          
      </Col>

        <Col lg="8" md="8" className="center">
          Precision3D.NET
        </Col>
        <Carousel/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
});
  // authState: state.authState,
  // firebaseUser: state.firebaseUser,
  // GREGORY: state.GREGORY,


const mapDispatchToProps = dispatch => ({
  registerAction: (name, email, password) =>
    dispatch(registerAction(name, email, password)),
    loginAction: (email, password) => dispatch(loginAction(email, password)),
    loginActionGoogle: () => dispatch(loginActionGoogle())

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);



// <li>The first model is on us</li>
// <li>Every 5th model is free</li>
// <li>Reduced pricing for jobs with multiple parts</li>
// <li>Free Shipping</li>
