import React from "react";
// import ReactDOM from 'react-dom'; 
import { connect } from "react-redux";
import addStatCardAction from "actions/addStatCardAction";
// import getAllStatCardsAction from "actions/getAllStatCardsAction";
// import getUserAction from "actions/getUserAction";
import { Button } from "reactstrap";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";


class Header extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.user = firebase.auth.currentUser().bind(this);
  //     var user = firebase.auth.currentUser();
      
  //     var name, email, photoUrl, uid, emailVerified;
  // }
  componentDidMount() {
    // this.props.getAllStatCardsAction();
    // this.props.getUserAction();
  }

  render() {
    
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
          </Container>
        </div>
      </>
    );
  }
}

  // getUserAction: () => dispatch(getUserAction()),
const mapStateToProps = state => ({
  ...state
});
// const mapDispatchToProps = dispatch => ({
//   getAllStatCardsAction: () => dispatch(getAllStatCardsAction()),
//   addStatCardAction: (
//     statName,
//     statDescription,
//     statIcon,
//     statIconColor,
//     statFooterIcon,
//     statFooterIconState,
//     statFooterPercentage,
//     statFooterText
//   ) =>
//     dispatch(
//       addStatCardAction(
//         statName,
//         statDescription,
//         statIcon,
//         statIconColor,
//         statFooterIcon,
//         statFooterIconState,
//         statFooterPercentage,
//         statFooterText
//       )
//     )
// });
export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(Header);
