import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  CardText
} from "reactstrap";

const DentalCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/platform_dental_group.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Dental</CardTitle>
              <CardText>
                Retainers, Guides, Splits,<br/>
                Dentures, and more...
              </CardText>
              <Button
                color="primary"
                href="/home/dental"
                // onClick={e => e.preventDefault()}
              >
                Dental
              </Button>
            </CardBody>
          </Card>
);

export default DentalCard;

