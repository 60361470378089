import React from "react";
import { connect } from "react-redux";
import {loginAction, loginActionGoogle, updateGoogleAction} from "actions/loginAction";
// import loginActionGoogle from "actions/loginActionGoogle";
import {firebaseAuth} from 'firebaseConfig'; 

// import { GoogleLogin } from 'react-google-login';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

// const responseGoogle = async (response) => {

//     if(response.w3.ofa) {
//       localStorage.setItem("user", JSON.stringify(response));
//       localStorage.setItem("appToken", JSON.stringify(response.googleId));
//       // await window.location.reload();
//     }
//     console.log(response);
// }

class Login extends React.Component {

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  };


  constructor(props) {
      super(props);
      this.state = {...props, email: "", password: "" }
    }
  
  componentWillMount()    {
      if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          try {
            const firebaseUser = localStorage.getItem("firebaseUser")
            this.setState({authState: {loggedIn:true, user:firebaseUser } });
          } catch(e) { console.error(e); console.log("unable to restore from localStorage")}
        }
      } else { console.log("Authstate confirmed") }
  
      firebaseAuth().onAuthStateChanged(user => {
          if (user) {
             // updateGoogleAction()
              console.log("User signed in: ", JSON.stringify(user.displayName));
              // localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("appToken", user.uid);
              this.setState({authState: {loggedIn:true, user:user} });
              // this.props.history.push("/client/profile")//, this.state)
          }
      });
   } 
  render() {
// {this.props.history.push("/client/profile")}
    console.log("login this.state:",this.state)
    console.log("login this.props:",this.props)
    console.log("appToken: ",localStorage.getItem("appToken"))
    if (this.props.authState.loggedIn) { 
      this.props.history.push("/client/profile");
      return (<> 
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                Signed In
             </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <Button
                href="/client/profile"
                > Get Started </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        </>);

      // this.props.history.push("/client/profile")
    } else { return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
             </div>
              <div className="btn-wrapper text-center">


                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  onClick= {() => 
                    {this.props.loginActionGoogle(); } //this.props.updateGoogleAction();}
                   }>
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={e => this.onChange("email", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={e => this.onChange("password", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() =>
                      this.props.loginAction(
                        this.state.email,
                        this.state.password
                      )
                    }
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
      );
    }
  }
}

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
  updateGoogleAction: () => dispatch(updateGoogleAction()),
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  loginActionGoogle: () => dispatch(loginActionGoogle())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
