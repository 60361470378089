import React from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button } from "reactstrap";
import firebase from 'firebaseConfig';


import {Checkout} from 'components/Checkout/CheckoutForm';
import UploadComponent from 'components/Upload/UploadComponent';
import FileGallery from 'components/Gallery/FileGallery';
import Shipping from 'components/Forms/Shipping'
import {
//   Badge,
  Card, CardBody, CardTitle, Container, Row, Col,
  CardHeader,
  UncontrolledAlert,
//   CardFooter,
//   DropdownMenu,
//   DropdownItem,
//   UncontrolledDropdown,
//   DropdownToggle,
//   Media,
//   Pagination,
//   PaginationItem,
//   PaginationLink,
//   Progress,
  Table,
//   UncontrolledTooltip
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import MessengerCustomerChat from 'react-messenger-customer-chat';

const downloadRef = firebase.storage()

class NewOrder extends React.Component {
  // _isMounted = false;
  // componentDidMount(){
  //   this._isMounted = true;
  // }      
  // componentWillUnmount(){
  //   this._isMounted = false;
  // } 

  constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this)
        this.gallery_handler = this.gallery_handler.bind(this)
        this.getSelectedCount = this.getSelectedCount.bind(this);
        this.on_payment_success = this.on_payment_success.bind(this);

        this.state = {
          ...props,
            images: [],
            // # Image Uploads
            filenames: [],
            downloadURLs: [],
            isUploading: false,
            uploadProgress: 0
        };
    }

  showAlert(type, message) {
    this.setState({
      alert: true,
      alertData: { type, message }
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 4000)
  }

  componentWillMount() {

    // if (this.state.authState === undefined) { 
    //     console.log("Authstate unconfirmed")
    //   if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({...this.state, authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser"))} });          
    //     }        
    // } else { 
    //   if (this.state.authState.loggedIn === undefined) { 
    //     if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({...this.state, authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser"))} });          
    //     }
    //   } else { console.log("Authstate confirmed") }
    // }
    this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });              
  }
  on_payment_success() {
    // return (
    // <UncontrolledAlert className="alert-default" fade={true}>
    //   <span className="alert-inner--icon">
    //     <i className="ni ni-like-2" />
    //   </span>{" "}
    //   <span className="alert-inner--text">
    //     <strong>Success!</strong> Your parts are being processed for growing now.
    //   </span>
    // </UncontrolledAlert>)
    this.showAlert('success', 'Your parts are being processed for growing now.');
  }
  
  gallery_handler(value) {
    this.setState({ images: value})
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
    console.log('[print]', this.state)

  };

  getSelectedCount () {
    var selected = [];
    for(var i = 0; i < this.state.images.length; i++)
        if(this.state.images[i].isSelected === true)
            selected.push(i);
    return selected.length;
  }    
  getSelectedFiles () {
    var selected = [];
    for(var i = 0; i < this.state.images.length; i++)
        if(this.state.images[i].isSelected === true)
            selected.push(this.state.images[i].thumbnailCaption);
    return selected;
  }    

  render() {
    let message_authorize_charge = 'I authorize Precision3D.NET to charge this credit card'
    let message_payment_header = 'Payment Details'
    if (this.getSelectedCount() > 0) {
      message_authorize_charge = 'I authorize Precision3D.NET to charge $' + this.getSelectedCount() * 35  + ' to this credit card';
      message_payment_header = 'Payment Details for ' + this.getSelectedCount() + ' parts'
    }
    console.log("[NewOrder] state: ", this.state)
    console.log("[NewOrder] props: ", this.props)

    return (
      <>
        <MessengerCustomerChat
            pageId="6194773961"
            appId="2608038802650771"
            htmlRef="https://precision3d.net"
        />
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>

        <Col >
          <Card className="shadow p-3 mb-5 bg-white rounded">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Place a new order for parts</h3>
                </CardHeader>
              <CardBody>
              To make a new order, upload or select an existing file then confirm your shipping and payment details. 
               {this.state.alert && <div className={`alert alert-${this.state.alertData.type}`} role='alert'>
                  <div className='container'>
                    {this.state.alertData.message}
                  </div>
                </div>}

              </CardBody>
              </Card>

              <Card className="shadow border-2">
              <CardHeader className=" bg-transparent">
              File Selection
            </CardHeader>

            <CardBody>
            Select an existing file or upload a new one here <UploadComponent/> <p/>
            {/*this.state.images[0].join('')*/}            
            <FileGallery images = {this.state.images} handler = {this.gallery_handler}/>
            </CardBody>
            </Card>
            <br/>
    
            <Row>
              <Col>
              <br/>
              <Card className="shadow border-2">
                <CardHeader className=" bg-transparent">
                  <div className="btn-wrapper text-center">
                   Shipping Details
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Shipping handler = {this.onChange}/>
                </CardBody>
              </Card>  
              
                </Col>
                <Col>
                <br/>
              <Card className="shadow border-2">
                  <CardHeader className=" bg-transparent">
              <div className="btn-wrapper text-center">
               <center>
                </center>
                  {message_payment_header}
              </div>
            </CardHeader>

              <CardBody className="px-lg-5 py-lg-5">
              {message_authorize_charge}<br/><p/>
                <Checkout 
                  // name={this.state.name}
                  // email={this.state.email}
                  // phone={this.state.phone}
                  files={ this.getSelectedFiles() }
                  amount={ this.getSelectedCount() * 35 } 
                  firebaseUser={this.state.authState.user}
                  stripePublicKey='pk_test_XeKtUlm8wbZA71uEzbj9tvtX00iJKLnwlJ' 
                  handleResult={this.on_payment_success} />
                </CardBody>
              </Card>
            </Col>
          </Row>
         </Col>
        <br/>
      </Container>
    </>
    );    
   }
}


const mapStateToProps = state => ({
  ...state
});
// const mapDispatchToProps = dispatch => ({
// });
//   // mapDispatchToProps

export default connect(
  mapStateToProps,
)(NewOrder);
