import React from "react";
import Shipping from 'components/Forms/Shipping';
import Customer from 'components/Forms/Customer';
import firebase from 'firebaseConfig';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import MessengerCustomerChat from 'react-messenger-customer-chat';

const appToken = localStorage.getItem("appToken")
const orderRef = firebase.database().ref().child("users/"+appToken+"/orders");

class Profile extends React.Component {
  constructor(props) {
      super(props);
      this.state = { ...props, orders:[] }
      this.onChange = this.onChange.bind(this)      
  }  
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
    orderRef.off()

  }       
  componentWillMount() {

    this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
    let orders = []
    var that = this;
    console.log("Orders ", this.appToken)
 
    // this.state.orders = orders
    orderRef.on('value', function(snapshot) {
        snapshot.forEach(function(data) { 
          orders.push(data.val()); 
          console.log("order data...",data.toJSON()); 
          that.setState({orders:orders})    
          });
      });    
  } 

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
    console.log('[print]', this.state)
  };
      
  render() {
    // console.log("profile state", this.state)
    var my_orders
    if (this.state.orders[0]) { 
      const completed_jobs = this.state.orders.reduce((idx, currentVal) => {
        if (currentVal.status === 'Complete') { idx += 1;} return idx; }, 0 )
      const open_jobs = this.state.orders.reduce((idx, currentVal) => {
        if (currentVal.status !== 'Complete') { idx += 1;} return idx; }, 0 )
      
        my_orders = 
          <div className="card-profile-stats d-flex justify-content-center mt-md-5">
            <div>
              <span className="heading">{completed_jobs}</span>
              <span className="description">Jobs Completed</span>
            </div>
            <div>
              <span className="heading">{open_jobs}</span>
              <span className="description">Jobs In Progress</span>
            </div>
          </div>
    } else { my_orders = <div className="card-profile-stats d-flex justify-content-center mt-md-5"> Loading... </div>}



    return (
      <>
      <MessengerCustomerChat
        pageId="6194773961"
        appId="2608038802650771"
        htmlRef="https://precision3d.net"
      />
        <UserHeader />      
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#disabled" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={this.state.authState.user.photoURL}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  {/*}
                  <div className="d-flex justify-content-between">
                    <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Message
                    </Button>
                  </div> */}
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                    {my_orders}
                    </div>
                  </Row>
                  {/*}
                  <div className="text-center">
                    <h3>
                      

                      <span className="font-weight-light">, 27</span>
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Bucharest, Romania
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Solution Manager - Creative Tim Officer
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div>
                    <hr className="my-4" />
                    <p>
                      Ryan — the name taken by Melbourne-raised, Brooklyn-based
                      Nick Murphy — writes, performs and records all of his own
                      music.
                    </p>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Show more
                    </a>
                  </div>
                */}
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    {/*<Col className="text-right" xs="4">
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          size="sm"
                        >
                          Settings
                        </Button>
                      </Col>*/}                  
                  </Row>
                </CardHeader>
                <CardBody>

                    <h6 className="heading-small text-muted mb-4">
                      Customer information
                    </h6>
                    <div className="pl-lg-4">
                      <Customer handler = {this.onChange}/>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Shipping information
                    </h6>
                    <div className="pl-lg-4">
                      <Shipping handler = {this.onChange}/>
                    </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;





                    // <hr className="my-4" />
                    // {/* Description */}
                    // <h6 className="heading-small text-muted mb-4">About me</h6>
                    // <div className="pl-lg-4">
                    //   <FormGroup>
                    //     <label>About Me</label>
                    //     <Input
                    //       className="form-control-alternative"
                    //       placeholder="A few words about you ..."
                    //       rows="4"
                    //       defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                    //       Open Source."
                    //       type="textarea"
                    //     />
                    //   </FormGroup>
                    // </div>


                        // if (this.state.authState === undefined) { 
    //     console.log("Authstate unconfirmed")
    //   if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser"))} });          
    //     }        
    // } else { 
    //   if (this.state.authState.loggedIn === undefined) { 
    //     if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser"))} });          
    //     }
    //   } else { console.log("Authstate confirmed") }
    // }