import React, {Component} from 'react';

import STRIPE_PUBLISHABLE from 'components/Checkout/stripe';
import PAYMENT_SERVER_URL from 'components/Checkout/server';
import axios from 'axios';
import StripeScriptLoader from 'react-stripe-script-loader'
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'

import firebase from 'firebaseConfig';

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

// const querystring = require('querystring')
const CURRENCY = 'USD';

const fromDollarToCent = amount => parseInt(amount * 100);

const successPayment = (data, props) => {
  console.log('success', 'Payment Successful');
  const eventsRef = firebase.database().ref("events")
  const orderRef = firebase.database().ref().child("users/"+props.firebaseUser.uid+"/orders");
  eventsRef.on('value', function(snapshot) {
    if (snapshot.exists()) {
          var data = snapshot.val()
          var key = Object.keys(data)[0]
          var receipt = data[key].data.object
          if (receipt.metadata.userid === props.firebaseUser.uid) {
            const params = {
              created: receipt.created,
              amount: receipt.amount,
              paid: receipt.paid,
              files: receipt.metadata.files,
              receipt_id: receipt.id,
              receipt_url: receipt.receipt_url,
              material: "Casting Resin",
              status: "Received"
            };
            orderRef.child(key).set(params).then(() => {
                console.log('success', 'Your order was received successfull');
              }).catch(() => {
                console.log('danger', 'Your order had an issue being received');
              });
          }

    }
  })

   
};

const errorPayment = data => {
  alert('Payment Error', data);

};

const onToken = (props) => (token) =>
  {

    // console.log(token.token.id)
        // description: "3D Parts",
    const body = 
      {
        source: token.token.id,
        currency: CURRENCY,
        amount: fromDollarToCent(props.amount),
        userid: props.firebaseUser.uid,
        files: props.files.join(',')
      };
        // ,
        // email: props.email,
        // phone: props.phone,
    const hostname = window && window.location && window.location.hostname;
    const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
    const URL = (hostname === 'kronos9.com') ? "http://fanous.com:8999/charge" : "http://fanous.com:9000/charge"
    axios({
      method: 'post',
      url: PROXY_URL+URL,
      headers: {'Content-Type':'text/plain'},
      data: body
      })
    .then(response => { successPayment(response, {...props}); console.log(response); })
    .catch(err => console.log(err));

  }

export class _CardForm extends Component {
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
  }      


  state = {
    errorMessage: '',
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };


  handleSubmit = (ev) => {
    ev.preventDefault();
    if (this.props.amount > 0){
      if (this.props.stripe) {
        this.props.stripe
          .createToken(
          // {name: this.state.name,
          //   address_city: this.state.city,
          //   address_line1: this.state.address1,
          //   address_line2: this.state.address2,
          //   address_state: this.state.state,
          //   address_country: this.state.country}
          // ).then((token) => console.log('[token]', payload));
          ).then( (token) => onToken({...this.props})(token) 
          );
      } else {
        console.log('Form submitted before Stripe.js loaded.');
      }
    } else { console.log("Needing to add a product")}
  };

  render() {
      // firebaseUser = JSON.parse(localStorage.getItem("firebaseUser"));
          // <Label> Card details for {this.props.firebaseUser.user.displayName} </Label><br/>
    // if (this._isMounted) {
      return (
        <div>
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <FormGroup>
        
            <Label className="input-group-alternative" style={{width:'500px'}}>
                <CardElement
                onChange={this.handleChange}
                {...createOptions()} />
            </Label>
            <div className="error" role="alert">
              {this.state.errorMessage}
            </div><br/>
            <Button>Pay</Button>
            </FormGroup>
          </Form>
        </div>
      );
    // } else { return (<></>); }
  }
}

const CardForm = injectStripe(_CardForm);

          //   <StripeScriptLoader
          //     uniqueId='myUniqueId'
          //     script='https://js.stripe.com/v3/'
          //     loader="Loading...">
          //   <StripeProvider apiKey="pk_test_XeKtUlm8wbZA71uEzbj9tvtX00iJKLnwlJ">
          // </StripeProvider>
          // </StripeScriptLoader>
export class Checkout extends Component {
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
  }      

  // stripePublicKey = 'pk_test_XeKtUlm8wbZA71uEzbj9tvtX00iJKLnwlJ';
    constructor (props) {
        super(props);
   //      this.state = { stripePublicKey: 'pk_test_XeKtUlm8wbZA71uEzbj9tvtX00iJKLnwlJ'  };
   //      // consol
   }
      // <StripeProvider apiKey='pk_test_XeKtUlm8wbZA71uEzbj9tvtX00iJKLnwlJ'>
  render() {

    const scriptLoader = (
        <StripeScriptLoader
                uniqueId='precision3Dnetworks'
                script='https://js.stripe.com/v3/'
                loader="Loading...">
          <StripeProvider apiKey={this.props.stripePublicKey} >
              <CardForm firebaseUser={this.props.firebaseUser} handleResult={this.props.handleResult} {...this.props} />
          </StripeProvider>
        </StripeScriptLoader>
      );
    console.log('[CheckoutForm] ', this.props)
    return (
        <Elements>
        { scriptLoader }
        </Elements>
      );
  }
}

      // } else { return (<></>); }
