import React from 'react';
// import { Row, Col, Jumbotron } from 'reactstrap';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const padding={ 
  paddingLeft: 40, 
  paddingRight: 40
};

const responsive = {
  0: { items: 1 },
  600: { items: 1 },
  1024: { items: 1 },
};

// const pStyle = {
//   color: 'white',
//   font: 'bold 24px/45px Helvetica, Sans-Serif',
//   background: 'rgba(0, 0, 0, 0.4)',
//   padding: '10px',
// };
// const hStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '25%',
//   width: '50%',
// };
// const hStyle50 = {
//   position: 'absolute',
//   top: '50%',
//   left: '0',
//   width: '50%',
// };
const iStyle = {
  position: 'relative',
  width: '80%',
};
const sample_01 = require('assets/img/product/sample_01.jpg');
const sample_02 = require('assets/img/product/sample_02.jpg');
const sample_03 = require('assets/img/product/sample_03.jpg');
const sample_04 = require('assets/img/product/sample_04.jpg');
const sample_05 = require('assets/img/product/sample_05.jpg');
const sample_06 = require('assets/img/product/sample_06.jpg');
const sample_07 = require('assets/img/product/sample_07.jpg');
const sample_08 = require('assets/img/product/sample_08.jpg');
// eslint-disable-next-line
const platform_01 = require('assets/img/product/platform_01.jpg');
// eslint-disable-next-line
const platform_02 = require('assets/img/product/platform_02.jpg');

const Carousel = () => (
<div>      <center>
        <AliceCarousel
          autoHeight={true}
          mouseDragEnabled={false}
          responsive={responsive}
          dotsDisabled={true}
          autoPlay={true}
          autoPlayInterval={3000}
          buttonsDisabled={true}
          stagePadding={ padding }
          stopAutoPlayOnHover={false}
        >
          <div>
            {' '}
            <img
              style={iStyle}
              src={sample_01}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>

          <div>
            <img
              style={iStyle}
              src={sample_02}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>

          <div>
            <img
              style={iStyle}
              src={sample_03}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>

          <div>
            <img
              style={iStyle}
              src={sample_04}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>


          <div>
            <img
              style={iStyle}
              src={sample_05}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>

          <div>
            <img
              style={iStyle}
              src={sample_06}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>

          <div>
            <img
              style={iStyle}
              src={sample_07}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>


          <div>
            <img
              style={iStyle}
              src={sample_08}
              alt="Sample"
              className="img-fluid rounded"
            />
          </div>

        </AliceCarousel>
      </center>

    <p/>
  </div>
);

export default Carousel;



//   <img
//     style={iStyle}
//     src={sample_01}
//     alt="Sample"
//     className="img-fluid rounded"
//   />
//   <h style={hStyle}>
//     <p style={pStyle}>Sample</p>
//   </h>
// </div>

