import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  CardText
} from "reactstrap";

const JewelryCard = () => (
          <Card style={{ width: "18rem" }}>
            <CardImg
              alt="..."
              src={require('assets/img/product/platform_02.jpg')}
              top
            />
            <CardBody>
              <CardTitle>Jewelry</CardTitle>
              <CardText>
                Rings, Earrings, Pendants,<br/>
                Bracelets, and more...
              </CardText>
              <Button
                color="primary"
                href="/home/jewelry"
                // onClick={e => e.preventDefault()}
              >
                Jewelry
              </Button>
            </CardBody>
          </Card>        
);

export default JewelryCard;

