import React from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button } from "reactstrap";
import firebase from 'firebaseConfig';

 
// core components
import Gallery from 'react-grid-gallery';
// import getAllUploadsAction from "actions/getAllUploadsAction";
const downloadRef = firebase.storage()
const userAuth = localStorage.getItem("appToken")
const filesRef = firebase.database().ref().child("users/"+userAuth+"/files");


class FileGallery extends React.Component {
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
    filesRef.off()
    // downloadRef.off()
  }      

  constructor(props){
      super(props);
      this.state = {
        ...props,
          // # Image Gallery
          images: [], 
          // this.props.images,
          selectAllChecked: false,
      };
      // this.images = this.images.bind(this)
      this.onSelectImage = this.onSelectImage.bind(this);
      this.getSelectedImages = this.getSelectedImages.bind(this);
      this.getSelectedCount = this.getSelectedCount.bind(this);
      this.onClickSelectAll = this.onClickSelectAll.bind(this);
  }

  componentWillMount() {
    if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          let firebaseUser = localStorage.getItem("firebaseUser")
          this.setState({authState: {loggedIn:true, user:firebaseUser } });          
        }
    } else { console.log("Authstate confirmed") }

    let IMAGES = [];
    // downloadRef.ref("users/"+localStorage.getItem("appToken")+"/images").listAll().then(function(result) {
    //   if (this._isMounted) {
    //     result.items.forEach(function(imageRef) { 
    //       imageRef.getMetadata().then(function(metadata) {
    //           imageRef.getDownloadURL().then(function(url) {
    //               IMAGES.push( {src : url, thumbnail : url,  thumbnailWidth: 200, thumbnailHeight: 200, thumbnailCaption: metadata.name})
    //               localStorage.setItem("uploads", JSON.stringify(IMAGES));
    //         }).catch(function(error) { console.log(error); });
    //       }).catch(function(error) { console.log(error); });
    //     })
    //   }
    // }).catch(function(error) { console.log(error);});
    // this.setState({images: IMAGES}); 
    
    let files = []
    let keys = []
    var that = this;
    filesRef.on('value', function(snapshot) {
      // if (that._isMounted) {
        snapshot.forEach(function(data) { 
          const {filename, urlFile, urlImage, uploadDate, notes} = data.val()
          files.push(data.val()); 
          keys.push([data.key,data.val()])
          console.log("files data...",data.toJSON()); 
          that.setState({files:files})    
          that.setState({keys:keys})
          IMAGES.push( {src : urlImage, thumbnail : urlImage,  thumbnailWidth: 200, thumbnailHeight: 200, thumbnailCaption: filename})              
          localStorage.setItem("uploads", JSON.stringify(IMAGES));
          that.props.handler(IMAGES)
        }); 
      // }
    });
    this.setState({images: JSON.parse(localStorage.getItem("uploads"))});
  }
  
    allImagesSelected (images){
        var f = images.filter(
            function (img) {
                return img.isSelected === true;
            }
        );
        return f.length === images.length;
    }

    onSelectImage (index, image) {
        var images = this.state.images.slice();
        var img = images[index];
        if(img.hasOwnProperty("isSelected"))
            img.isSelected = !img.isSelected;
        else
            img.isSelected = true;

        this.setState({
            images: images
        });
        this.props.handler(images)
        if(this.allImagesSelected(images)){
            this.setState({
                selectAllChecked: true
            });
        }
        else {
            this.setState({
                selectAllChecked: false
            });
        }
    }

    getSelectedImages () {
        var selected = [];
        for(var i = 0; i < this.state.images.length; i++)
            if(this.state.images[i].isSelected === true)
                selected.push(i);
        return selected;
    }
    getSelectedCount () {
        var selected = [];
        for(var i = 0; i < this.state.images.length; i++)
            if(this.state.images[i].isSelected === true)
                selected.push(i);
        return selected.length;
    }    
    onClickSelectAll () {
        var selectAllChecked = !this.state.selectAllChecked;
        this.setState({
            selectAllChecked: selectAllChecked
        });

        var images = this.state.images.slice();
        if(selectAllChecked){
            for(var i = 0; i < this.state.images.length; i++)
                images[i].isSelected = true;
        }
        else {
            for(var i = 0; i < this.state.images.length; i++)
                images[i].isSelected = false;
        }
        this.setState({
            images: images
        });
    }
        // <div style={{
        //     padding: "2px",
        //     color: "#666"
        //   }}>Selected images: {this.getSelectedCount().toString()}</div>
  render() {
    console.log("FileGallery state", this.state)
    console.log("FileGallery props", this.props)
  	return (
      <>
        <div style={{
            display: "block",
            minHeight: "1px",
            width: "100%",
            border: "1px solid #ddd",
            overflow: "auto"}}>
        <Gallery
          enableLightbox={false}
          onTouchEnd={false}
          isSelected={this.state.selectAllChecked}
          onClick={this.onClickSelectAll}
          images={this.props.images}
          onSelectImage={this.onSelectImage}
          showLightboxThumbnails={true}
          onClickThumbnail={this.onSelectImage}
          /></div>
      </>
  		);    
   }
}
const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileGallery);
