import React from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button } from "reactstrap";
import firebase from 'firebaseConfig';

// import getCustomerOrders from 'actions/getCustomerOrders'

// import {Checkout} from 'components/Checkout/CheckoutForm';
// import UploadComponent from 'components/Upload/UploadComponent';
// import FileGallery from './FileGallery';
// import Shipping from 'components/Forms/Shipping'

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import MessengerCustomerChat from 'react-messenger-customer-chat';

// const downloadRef = firebase.storage()
// const eventsRef = firebase.database().ref("events").orderByChild('data/object/metadata/userid')
const appToken = localStorage.getItem("appToken")
const filesRef = firebase.database().ref().child("users/"+appToken+"/files");

class Files extends React.Component {
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
    filesRef.off()
  }      
      
  constructor(props){
      super(props);
      this.deleteFile = this.deleteFile.bind(this)
      this.onChange = this.onChange.bind(this)
      this.to_datetime = this.to_datetime.bind(this)
      this.state = {files:[]}
  }
  // componentDidMount() {
    // this.props.getCustomerOrders();
    // this.props.getAllUploadsAction();
  // }
  appToken = localStorage.getItem("appToken")
    
  componentWillMount() {

    // if (this.state.authState === undefined) { 
    //     console.log("Authstate unconfirmed")
    //   if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
    //     }        
    // } else { 
    //   if (this.state.authState.loggedIn === undefined) { 
    //     if (localStorage.getItem("appToken") !== undefined) {
    //       console.log("appToken found")
    //       this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
    //     }
    //   } else { console.log("Authstate confirmed") }
    // }
    this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          

    let files = []
    let keys = []
    var that = this;
    filesRef.on('value', function(snapshot) {
      // if (that._isMounted) {
        snapshot.forEach(function(data) { 
          files.push(data.val()); 
          keys.push([data.key,data.val()])
          console.log("files data...",data.toJSON()); 
          that.setState({files:files})    
          that.setState({keys:keys})    
        }); 
      // }
    });
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
    console.log('[print]', this.state)
  };

  to_datetime = (epoch) => {
    var d = new Date(epoch*1000);
    // var month = 
    var dformat = (d.getMonth()+1).toString().padStart(2,'0') + "/" + 
          (d.getDate()).toString().padStart(2,'0') + "/" + 
          (d.getFullYear()).toString().padStart(2,'0') + " " + 
          (d.getHours()).toString().padStart(2,'0') + ":" + 
          (d.getMinutes()).toString().padStart(2,'0') + ":" + 
          (d.getSeconds()).toString().padStart(2,'0');
    return dformat.toString();
  };



  deleteFile = (filename) => { 
    var deleteFileRef = firebase.storage().ref("users/"+appToken+"/files").child(filename)
    deleteFileRef.delete().then(function() { console.log(filename, " file successfully deleted")}).catch(function(error){console.log("error", error)})
    
    var filesState = []
    for (let val of this.state.keys) {
      if (val[1].filename === filename) {
        firebase.database().ref("users/"+appToken+"/files").child(val[0]).remove().then(function() { console.log(filename, " db successfully deleted")}).catch(function(error){console.log("error", error)})       }
       else { filesState.push(val[1]) }
    }
  this.setState({files:filesState})
  }


  render() {
    console.log(this.appToken, this.state)
    var my_files 
    if (this.state.files[0]) { 
        my_files = this.state.files.map((prop, key) => {
                    return (

                    <tr key={key}>



                      

                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-primary" />
                          {prop.filename}
                        </Badge>
                      </td>

                      <td>{this.to_datetime(prop.uploadDate)}</td>



                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt={prop.filename}
                              src={prop.urlImage}
                            />
                          </a>
                          </Media>
                        </Media>
                      </th>




                      <td>
                        <div className="test-left">
                            <a href={prop.urlFile}> Download </a>
                        </div>
                      </td>
                      
                      <td>
                        <div className="test-left">
                          {prop.notes}
                        </div>
                      </td>
                      


                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={e => this.deleteFile(prop.filename)}
                            >
                              Delete File
                              {/*e => e.preventDefault()*/}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    )})
              } 
    else { my_files = <tr><td> Loading... </td></tr> }


  	return (
      <>
        <MessengerCustomerChat
            pageId="6194773961"
            appId="2608038802650771"
            htmlRef="https://precision3d.net"
        />
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
                 {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">My Files and Details</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">File Name</th>
                      <th scope="col">Time Uploaded</th>
                      <th scope="col">Image</th>
                      <th scope="col">Link</th>
                      <th scope="col">Note</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                 { /*this.state.files &&
                  this.state.files[0] &&*/}
                  {my_files}







                  </tbody>
                </Table>
                {/*
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              */}
              </Card>
            </div>
          </Row>
        </Container>
      </>
  		);    
   }
}

        // {this.props.authState}

const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = dispatch => ({
//   logoutAction: () =>
//     dispatch(logoutAction())

  // getAllUploadsAction: () => dispatch(getAllUploadsAction())

  // ,addStatCardAction: (
  //   statName,
  //   statDescription,
  //   statIcon,
  //   statIconColor,
  //   statFooterIcon,
  //   statFooterIconState,
  //   statFooterPercentage,
  //   statFooterText
  // ) =>
  //   dispatch(
  //     addStatCardAction(
  //       statName,
  //       statDescription,
  //       statIcon,
  //       statIconColor,
  //       statFooterIcon,
  //       statFooterIconState,
  //       statFooterPercentage,
  //       statFooterText
  //     )
  //   )
});
  // mapDispatchToProps
export default connect(
  mapStateToProps,
)(Files);
