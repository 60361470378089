import React from "react";
import { connect } from "react-redux";
// import { GoogleLogin } from 'react-google-login';
import {loginAction, loginActionGoogle} from "actions/loginAction"; //updateGoogleAction
import registerAction from "actions/registerAction";
import Carousel from 'components/Carousel/LandingCarousel';
import {CrownBridgeCard, ClearAlignerCard, SurgicalGuidesCard, SplintsGuardsCard, 
  PatternCastingCard, HawleyRetainersCard, DenturesCard} from 'components/Cards/DentalProducts';
import axios from 'axios';
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
} from "reactstrap";

const responseGoogle = (response) => {
    console.log(response);
}

class Industries extends React.Component {
 
  state = {
    name: "",
    email: "",
    password: ""
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  }; 

  handleUploadSuccess = async filename => {

    // Send to Blender API
    const body = 
      {
        url: "http://url",
        userid: "UserId",
        files: 'tooth.stl',
        material: "default"
      }; 
    const hostname = window && window.location && window.location.hostname;
    const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
    const URL = "http://li834-109.members.linode.com:9310/processUpload"
    axios({
      method: 'post',
      url: PROXY_URL+URL, 
      headers: {'Content-Type':'text/plain'},
      data: body
      })
    .then(response => { console.log(response); })
    .catch(err => console.log(err));

    // window.parent.location = window.parent.location.href;

  }

  render() {

    return (
      <>
      <center> 
      <Container>
        <div className="header-body text-center mb-5">
          <Row className="justify-content-center">
            <Col lg="10" md="6">
              <h1 className="text-white">Digital Dentistry - 3D Labs</h1>
              <h3 className="text-white">Retainers, Guides, Splits,Dentures, and more...</h3>
            </Col>
          </Row>
          <Row>
          </Row>
        </div>
      </Container>
        <Button
          color="primary"
          // href="/home/jewelry"
          onClick={this.handleUploadSuccess}//{e => e.preventDefault()}
        > Upload </Button>

      <Container>
                <div className="body text-center">
                  <h1 className="text-white mb-4">Currently Supported Parts</h1>
                </div>
      <Row>
              <Col className="col-sm">
                <span>
                <CrownBridgeCard/>
                </span>
              </Col>
              <Col className="col-sm">
                <span>
                <ClearAlignerCard/>
                </span>
              </Col>          
              <Col className="col-sm">
                <span>
                <SurgicalGuidesCard/>
                </span>
              </Col>
      </Row>
     <br/>
       <Row>
              <Col className="col-sm">
                <span>
                <SplintsGuardsCard/>
                </span>
              </Col>
              <Col className="col-sm">
                <span>
                <PatternCastingCard/>
                </span>
              </Col>          
              <Col className="col-sm">
                <span>
                <HawleyRetainersCard/>
                </span>
              </Col>
      </Row>
      <br/>
      <Row>
              <Col className="col-sm">
                <span>
                <DenturesCard/>
                </span>
              </Col>
      </Row>
      </Container>

      </center>
      </>
    );
  }
}


// <Col lg="10" md="8">
//           <h2 className="text-white">The first model is on us, every 5th model is free, with reduced pricing on jobs with multiple parts.</h2>
//           <br/>
//           <Carousel/>
//       </Col>

//       <Col lg="8" md="8">
//         <Card className="bg-secondary shadow border-0">
//             <CardHeader className="bg-transparent pb-5">
//               <div className="text-muted text-center mt-2 mb-4">
//                 <h2>Sign in and get started now</h2>
//               </div>
//               <div className="text-center">

//                 <Button
//                   className="btn-neutral btn-icon"
//                   color="default"
//                   onClick= {() => 
//                     {this.props.loginActionGoogle(); } 
//                    }>
//                   <span className="btn-inner--icon">
//                     <img
//                       alt="..."
//                       src={require("assets/img/icons/common/google.svg")}
//                     />
//                   </span>
//                   <span className="btn-inner--text">Google</span>
//                 </Button>

//               </div>
 
//             </CardHeader>
//             <CardBody className="px-lg-5 py-lg-5">
//               <div className="text-center text-muted mb-4">
//                 <small>Or sign up</small>
//               </div>
//               <Form role="form">
//                 <FormGroup>
//                   <InputGroup className="input-group-alternative mb-3">
//                     <InputGroupAddon addonType="prepend">
//                       <InputGroupText>
//                         <i className="ni ni-hat-3" />
//                       </InputGroupText>
//                     </InputGroupAddon>
//                     <Input
//                       placeholder="Name"
//                       type="text"
//                       onChange={e => this.onChange("name", e.target.value)}
//                     />
//                   </InputGroup>
//                 </FormGroup>
//                 <FormGroup>
//                   <InputGroup className="input-group-alternative mb-3">
//                     <InputGroupAddon addonType="prepend">
//                       <InputGroupText>
//                         <i className="ni ni-email-83" />
//                       </InputGroupText>
//                     </InputGroupAddon>
//                     <Input
//                       placeholder="Email"
//                       type="email"
//                       onChange={e => this.onChange("email", e.target.value)}
//                     />
//                   </InputGroup>
//                 </FormGroup>
//                 <FormGroup>
//                   <InputGroup className="input-group-alternative">
//                     <InputGroupAddon addonType="prepend">
//                       <InputGroupText>
//                         <i className="ni ni-lock-circle-open" />
//                       </InputGroupText>
//                     </InputGroupAddon>
//                     <Input
//                       placeholder="Password"
//                       type="password"
//                       onChange={e => this.onChange("password", e.target.value)}
//                     />
//                   </InputGroup>
//                 </FormGroup>
//                 <div className="text-muted font-italic">
//                   <small>
//                     password strength:{" "}
//                     <span className="text-success font-weight-700">strong</span>
//                   </small>
//                 </div>
//                 <Row className="my-4">
//                   <Col xs="12">
//                     <div className="custom-control custom-control-alternative custom-checkbox">
//                       <input
//                         className="custom-control-input"
//                         id="customCheckRegister"
//                         type="checkbox"
//                       />
//                       <label
//                         className="custom-control-label"
//                         htmlFor="customCheckRegister"
//                       >
//                         <span className="text-muted">
//                           I agree with the{" "}
//                           <a href="#pablo" onClick={e => e.preventDefault()}>
//                             Privacy Policy
//                           </a>
//                         </span>
//                       </label>
//                     </div>
//                   </Col>
//                 </Row>
//                 <div className="text-center">
//                   <Button
//                     className="mt-4"
//                     color="primary"
//                     type="button"
//                     onClick={() =>
//                       this.props.registerAction(
//                         this.state.name,
//                         this.state.email,
//                         this.state.password
//                       )
//                     }
//                   >
//                     Create account
//                   </Button>
//                 </div>
//               </Form>
//             </CardBody>
//           </Card>
//         </Col>
const mapStateToProps = state => ({
  ...state,
});
  // authState: state.authState,
  // firebaseUser: state.firebaseUser,
  // GREGORY: state.GREGORY,


const mapDispatchToProps = dispatch => ({
  registerAction: (name, email, password) =>
    dispatch(registerAction(name, email, password)),
    loginAction: (email, password) => dispatch(loginAction(email, password)),
    loginActionGoogle: () => dispatch(loginActionGoogle())

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Industries);



// <li>The first model is on us</li>
// <li>Every 5th model is free</li>
// <li>Reduced pricing for jobs with multiple parts</li>
// <li>Free Shipping</li>
