import React from "react";
import { connect } from "react-redux";
// import { GoogleLogin } from 'react-google-login';
import {loginAction, loginActionGoogle} from "actions/loginAction"; //updateGoogleAction
import registerAction from "actions/registerAction";
import Carousel from 'components/Carousel/LandingCarousel';
import {firebaseAuth} from 'firebaseConfig'; 
import MessengerCustomerChat from 'react-messenger-customer-chat';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container
} from "reactstrap";

const responseGoogle = (response) => {
    console.log(response);
}

class Register extends React.Component {
 
  // state = {
  //   name: "",
  //   email: "",
  //   password: ""
  // };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
  }; 
  constructor(props) {
      super(props);
      this.state = {...props, name: "", email: "", password: "" }
    }
  
  componentWillMount()    {
      if (this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          try {
            const firebaseUser = localStorage.getItem("firebaseUser")
            this.setState({authState: {loggedIn:true, user:firebaseUser } });
          } catch(e) { console.error(e); console.log("unable to restore from localStorage")}
        }
      } else { console.log("Authstate confirmed") }
  
      firebaseAuth().onAuthStateChanged(user => {
          if (user) {
             // updateGoogleAction()
              console.log("User signed in: ", JSON.stringify(user.displayName));
              // localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("firebaseUser", JSON.stringify(user));
              localStorage.setItem("appToken", user.uid);
              this.setState({authState: {loggedIn:true, user:user} });
              // this.props.history.push("/client/profile")//, this.state)
          }
      });
   }     
  render() {
    if (this.props.authState.loggedIn) { 
      this.props.history.push("/client/profile");
      return (<> 
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                Signed In
             </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <Button
                href="/client/profile"
                > Get Started </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        </>);

      // this.props.history.push("/client/profile")
    } else {
      return (
        <>
        <MessengerCustomerChat
pageId="6194773961"
appId="2608038802650771"
htmlRef="https://precision3d.net"
/>
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col lg="10" md="6">
                <h2 className="text-white">Welcome to Precision 3D Networks!</h2>
                <h3 className="text-white text">
                  Your price friendly provider for 3D printing, with next-day shipping.
                </h3>
              </Col>
            </Row>
          </div>
        </Container>

        <Col lg="10" md="8">
            <h2 className="text-white">Every 5th model is free, with reduced pricing on jobs with multiple parts.</h2>
            <br/>
            <Carousel/>
        </Col>

        <Col lg="8" md="8">
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-4">
                  <h2>Sign in and get started now</h2>
                </div>
                <div className="text-center">

                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    onClick= {() => 
                      {this.props.loginActionGoogle(); } 
                     }>
                    <span className="btn-inner--icon">
                      <img
                        alt="..."
                        src={require("assets/img/icons/common/google.svg")}
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>

                </div>
   
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Or sign up</small>
                </div>
                <Form role="form">
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Name"
                        type="text"
                        onChange={e => this.onChange("name", e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onChange={e => this.onChange("email", e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={e => this.onChange("password", e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-muted font-italic">
                    <small>
                      password strength:{" "}
                      <span className="text-success font-weight-700">strong</span>
                    </small>
                  </div>
                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            I agree with the{" "}
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Privacy Policy
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      color="primary"
                      type="button"
                      onClick={() =>
                        this.props.registerAction(
                          this.state.name,
                          this.state.email,
                          this.state.password
                        )
                      }
                    >
                      Create account
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </>
      );
    }
  }
}
const mapStateToProps = state => ({
  ...state,
});
  // authState: state.authState,
  // firebaseUser: state.firebaseUser,
  // GREGORY: state.GREGORY,


const mapDispatchToProps = dispatch => ({
  registerAction: (name, email, password) =>
    dispatch(registerAction(name, email, password)),
    loginAction: (email, password) => dispatch(loginAction(email, password)),
    loginActionGoogle: () => dispatch(loginActionGoogle())

});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);



// <li>The first model is on us</li>
// <li>Every 5th model is free</li>
// <li>Reduced pricing for jobs with multiple parts</li>
// <li>Free Shipping</li>
