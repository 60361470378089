import React from "react";
import { connect } from "react-redux";
// import {firebaseAuth} from 'firebaseConfig';
import firebaseAuth from 'firebaseConfig';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";



// function writeUserData(userId, name, email, imageUrl) {
//   firebase.database().ref('users/' + userId).set({
//     username: name,
//     email: email,
//     profile_picture : imageUrl
//   });
// }
const shippingFormRef = firebaseAuth.database().ref().child("users/"+localStorage.getItem("appToken")+"/shipping_address");

class Shipping extends React.Component {
  _isMounted = false;
  componentDidMount(){
    this._isMounted = true;
  }      
  componentWillUnmount(){
    this._isMounted = false;
    shippingFormRef.off()
  } 

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value
    });
    this.props.handler(stateName, value);
  };

  constructor(props) {
      super(props);
      this.state = {
        address1: "",
        address2: "", 
        city: "",
        state: "",
        zip: "",
        country: "",
        form: [],
      }
    }

 

  componentWillMount() {
    if (this.state.authState && this.state.authState.loggedIn === undefined) { 
        console.log("Authstate unconfirmed")
        if (localStorage.getItem("appToken") !== undefined) {
          console.log("appToken found")
          this.setState({authState: {loggedIn:true, user:JSON.parse(localStorage.getItem("firebaseUser")) } });          
        }
    } else { console.log("Authstate confirmed") }
    
    let that = this;
    shippingFormRef.on('value', snapshot => {
        if (snapshot.exists()) {
          const { address1, address2, city, state, zip, country } = snapshot.val();
          const data = { address1, address2, city, state, zip, country };
          this.setState({ address1: address1, address2: address2, city: city, state: state, zip: zip, country: country, form:[data]})
        } else {
          const data = { address1:"", address2:"", city:"", state:"", zip:"", country:""};
          this.setState({ address1:"", address2:"", city:"", state:"", zip:"", country:"", form:[data]})
          this.showAlert('danger', 'First visit to database reference'); 
        }
    })
  }
  // componentWillUnmount() {

  //   cancel subscr
  // }
  updateFirebase(e) {
    e.preventDefault();
    const params = {      
      address1: this.state.address1,
      address2:this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
    };
    console.log("E", e)
    if (params.address1===undefined) params.address1 = ""
    if (params.address2===undefined) params.address2 = ""
    if (params.city===undefined) params.city = ""
    if (params.state===undefined) params.state = ""
    if (params.zip===undefined) params.zip = ""
    if (params.country===undefined) params.country = ""

    shippingFormRef.update(params).then(() => {
      this.showAlert('success', 'Your data was updated successfull');
    }).catch(() => { this.showAlert('danger', 'Your data could not be updated'); });
     
  console.log(this.state)
    
  }

  showAlert(type, message) {
    this.setState({
      alert: true,
      alertData: { type, message }
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 4000)
  }

  render() {
    var shipping_information 
    if (this.state.form[0]) { 
        shipping_information = this.state.form.map(form =>
          <Form role="form" key="shipping_information">

            <Row>
              <Col md="12">            
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address1"> Address Line 1</label>
                    <Input
                      className="form-control-alternative"
                      key="input-address1"                    
                      onBlur={this.updateFirebase.bind(this)}
                      placeholder="Address 1"
                      type="address1"
                      onChange={e => this.onChange("address1", e.target.value)}
                      defaultValue={form.address1}                      
                    />
                </FormGroup>
              </Col>                
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address2"> Address Line 2</label>
                    <Input
                      className="form-control-alternative"
                      key="input-address2"                    
                      onBlur={this.updateFirebase.bind(this)}
                      placeholder="Address 2"
                      type="address2"
                      onChange={e => this.onChange("address2", e.target.value)}
                      defaultValue={form.address2}                      
                    />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-city"> City </label>
                    <Input
                      className="form-control-alternative"
                      key="input-city"                    
                      onBlur={this.updateFirebase.bind(this)}
                      placeholder="City"
                      type="city"
                      onChange={e => this.onChange("city", e.target.value)}
                      defaultValue={form.city}                                            
                    />
                </FormGroup>
                </Col>

              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-state"> State </label>
                    <Input
                      className="form-control-alternative"
                      key="input-state"                    
                      onBlur={this.updateFirebase.bind(this)}
                      placeholder="State"
                      type="state"
                      onChange={e => this.onChange("state", e.target.value)}
                      defaultValue={form.state}
                    />
                </FormGroup>
                </Col>

              <Col lg="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-zip"> Zip Code </label>
                    <Input
                      className="form-control-alternative"
                      key="input-zip"                    
                      onBlur={this.updateFirebase.bind(this)}
                      placeholder="Zip Code"
                      type="zip"
                      onChange={e => this.onChange("zip", e.target.value)}
                      defaultValue={form.zip}                                            
                    />
                </FormGroup>
              </Col>

              </Row>
            </Form>
            )
      } else { shipping_information = <div> Loading... </div> }
    return (
      <>        
        {this.state.alert && <div className={`alert alert-${this.state.alertData.type}`} role='alert'>
          <div className='container'>
            {this.state.alertData.message}
          </div>
        </div>}      
        {shipping_information}
      </>
    );
  }
}


const mapStateToProps = state => ({
  ...state
});

// export default connect(
//   mapStateToProps,
// )(Shipping);
export default Shipping;