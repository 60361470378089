import Index from "views/IndexTemp.jsx";
import About from "views/About.jsx";
import Profile from "views/Profile.jsx";
import Register from "views/Register.jsx";
import Login from "views/Login.jsx";
import Logout from "views/Logout.jsx";
import NewOrder from "views/NewOrder.jsx";
import Support from "views/Support.jsx";
import Files from "views/Files.jsx";
import Orders from "views/Orders.jsx";

import Landing from "views/Landing.jsx";
import Industries from "views/Industries.jsx";
import Industries_Dental from "views/Industries_Dental.jsx";
import Industries_Jewelry from "views/Industries_Jewelry.jsx";


var routes = [
  {
    invisible: true,
    path: "/industries",
    name: "Industries",
    icon: "ni ni-tv-2 text-primary",
    component: Industries,
    layout: "/home"
  },  
  {
    invisible: true,
    path: "/dental",
    name: "Industries_Dental",
    icon: "ni ni-tv-2 text-primary",
    component: Industries_Dental,
    layout: "/home"
  },  
  {
    invisible: true,
    path: "/jewelry",
    name: "Industries_Jewelry",
    icon: "ni ni-tv-2 text-primary",
    component: Landing,
    layout: "/home"
  },    {
    invisible: true,
    path: "/landing",
    name: "Landing",
    icon: "ni ni-tv-2 text-primary",
    component: Landing,
    layout: "/home"
  },
  {
    invisible: true,
    path: "/about",
    name: "About Precision3D.NET",
    icon: "ni ni-tv-2 text-primary",
    component: Landing,
    layout: "/home"
  },
  {
    invisible: true,
    path: "/about",
    name: "About Precision3D.NET",
    icon: "ni ni-tv-2 text-primary",
    component: About,
    layout: "/client"
  },
  {
    path: "/new",
    name: "New Order",
    icon: "ni ni-fat-add text-primary",
    component: NewOrder,
    layout: "/order"
  },
  {
    invisible: true,
    path: "/start",
    name: "New Order",
    icon: "ni ni-active-40 text-primary",
    component: NewOrder,
    layout: "/order"
  },  
  {
    invisible: true,
    path: "/print",
    name: "New Order",
    icon: "ni ni-active-40 text-primary",
    component: NewOrder,
    layout: "/order"
  },
  {
    path: "/orders",
    name: "My Orders",
    icon: "ni ni-bullet-list-67 text-primary",
    component: Orders,
    layout: "/client"
  },
  {
    path: "/files",
    name: "My Files",
    icon: "ni ni-single-copy-04 text-primary",
    component: Files,
    layout: "/client"
  },
  {
    path: "/profile",
    name: "Account Details",
    icon: "ni ni-single-02 text-primary",
    component: Profile,
    layout: "/client"
  },
  {
    path: "/support",
    name: "Support",
    icon: "ni ni-support-16 text-primary",
    component: Support,
    layout: "/client"
  },
    {
    invisible: true,
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    invisible: true,
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Logout,
    layout: "/client"
  },
  {
    invisible: true,
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Logout,
    layout: "/auth"
  },
  {
    invisible: true,
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  
];
export default routes;
